import { render, staticRenderFns } from "./dialog.vue?vue&type=template&id=2789207b&scoped=true&"
import script from "./dialog.vue?vue&type=script&lang=js&"
export * from "./dialog.vue?vue&type=script&lang=js&"
import style0 from "./dialog.vue?vue&type=style&index=0&id=2789207b&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2789207b",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VCol } from 'vuetify/lib/components/VGrid';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSelect } from 'vuetify/lib/components/VSelect';
installComponents(component, {VCol,VRow,VSelect})
