<template>
  <FormDialog
    :title="title"
    max-width="600px"
    @submit="submit"
    v-model="dialog"
    class="form"
  >
    <v-row
      class="mt-1 row-dialog"
      :class="{ 'd-flex justify-space-around': form.id == null }"
    >
      <v-col :cols="form.id ? 6 : 8">
        <v-select
          prepend-inner-icon="mdi-home"
          v-model="form.id"
          :items="id_khsx_lists"
          outlined
          dense
          clearable
          return-object
          :item-text="(item) => getName(item)"
          item-value="id"
          :rules="ruleListCompany"
        >
        </v-select>
      </v-col>
      <v-col v-if="form.id" cols="6">
        <div>
          <ul style="text-decoration: none;">
            <li>
              <p>Tên khách hàng :{{ form.id.ten }}</p>
            </li>
          </ul>
        </div>
      </v-col>
    </v-row>
  </FormDialog>
</template>

<script>
export default {
  props: {
    title: String,
  },
  data: () => ({
    dialog: false,
    isAdd: false,
    // id_khsx_lists: [],
    form: {
      id: null,
    },
    ruleListCompany: [(v) => !!v || "Khách hàng sản xuất không thẻ bỏ trống"],
  }),
  mounted() {},
  methods: {
    getName(item) {
      return `${item.id} - ${item.ma}`;
    },
    resetData() {
      this.showChoosenKhachHangSx();
    },
    show(item, id, mode) {
      if (mode && mode == "add") {
        this.isAdd = true;
      }
      this.id_khsx_lists = item;
      if (id) {
        this.form.id = this.id_khsx_lists.filter((el) => el.id == id)[0];
      }
      this.dialog = true;
    },
    close() {
      this.form.id = null;
      this.dialog = false;
    },
    submit() {
      if (this.isAdd) {
        this.$emit("submit", this.form.id, "add");
      } else {
        this.$emit("submit", this.form.id);
      }
      this.close();
    },
  },
};
</script>

<style scoped>
.row-dialog {
  margin-top: -40px;
}
</style>
