<template>
  <div class="flex-grow-1">
    <table>
      <thead>
        <tr>
          <th>Tên kho</th>
          <th>Địa chỉ</th>
          <th>Khu vực</th>
          <th>ID_KHSX</th>
          <th>Tọa độ</th>
          <th>Ghi chú</th>
          <th>Hành động</th>
        </tr>
      </thead>
      <tbody>
        <tr
          v-for="(item, index) in getKeyAccess(address)"
          :key="`field_${index}`"
        >
          <td
            v-for="([key, value], i) of convertItem(item)"
            :key="`field_${key}_${i}`"
            :class="{ width50: key == '' }"
          >
            <span v-if="index != index_update">
              <span v-if="key == 'area_offical_id'">
                {{ getAreaName(value) }}
              </span>
              <!-- <span
                v-else-if="
                  key === 'id_khsx' &&
                    id_khsx_lists.length === 1 &&
                    item.id_khsx === null
                "
              > -->
              <!-- {{ getUniqueIdKhsx(item) }} -->
              <!-- </span> -->
              <span v-else :title="value">
                {{ value }}
              </span>
            </span>
            <span v-else>
              <v-autocomplete
                v-if="key == 'area_offical_id'"
                v-model="form_edit[key]"
                :items="area_lists"
                outlined
                dense
                :label="getFieldWithKey(key)"
                :item-text="(item) => getAreaText(item)"
                item-value="id"
                hide-details
              />
              <div v-else-if="key == 'id_khsx'">
                <v-text-field
                  @click="openDialog(form_edit[key], 'edit')"
                  v-model="form_edit[key]"
                  :label="getFieldWithKey(key)"
                  autofocus
                  outlined
                  readonly
                  dense
                  style="width: auto"
                  class="mx-1"
                  hide-details
                >
                </v-text-field>
              </div>

              <map-picker
                v-model="form_edit.coordinate"
                v-else-if="key == 'coordinate'"
                :label="getFieldWithKey(key)"
              ></map-picker>
              <v-text-field
                v-else
                v-model="form_edit[key]"
                :label="getFieldWithKey(key)"
                autofocus
                outlined
                dense
                style="width: auto"
                class="mx-1"
                hide-details
              />
            </span>
          </td>
          <td>
            <div class="d-flex align-center">
              <v-btn
                small
                hide-details
                color="primary"
                icon
                @click="onAction('UPDATE', index, item)"
                v-if="index != index_update"
                title="Cập nhật"
              >
                <v-icon small>mdi-pencil</v-icon>
              </v-btn>
              <v-btn
                small
                hide-details
                color="success"
                icon
                @click="onUpdateItem(index)"
                v-if="index == index_update"
                title="Thực hiện cập nhật"
              >
                <v-icon small>mdi-pencil</v-icon>
              </v-btn>
              <v-btn
                small
                hide-details
                color="error"
                icon
                @click="onAction('DELETE', index, item)"
                title="Xóa"
                v-if="index != index_update"
              >
                <v-icon small>mdi-delete</v-icon>
              </v-btn>
              <v-btn
                small
                hide-details
                color="primary"
                icon
                @click="rollback(index)"
                v-if="index == index_update"
                title="Thoát"
              >
                <v-icon small>mdi-close</v-icon>
              </v-btn>
            </div>
          </td>
        </tr>
        <DiaLogIdKhachHangSx
          ref="diaLogIdKhSx"
          title="Chọn khách hàng sản xuất"
          @submit="bindChoosenId"
        ></DiaLogIdKhachHangSx>
      </tbody>
      <tfoot>
        <tr>
          <td>
            <v-text-field
              v-model="form.name"
              label="Tên kho"
              outlined
              dense
              style="width: auto"
              class="mx-1"
              hide-details
            />
          </td>
          <td>
            <v-text-field
              v-model="form.address"
              label="Địa chỉ"
              outlined
              dense
              style="width: auto"
              class="mx-1"
              hide-details
            />
          </td>
          <td>
            <v-autocomplete
              v-model="form.area_offical_id"
              :items="area_lists"
              outlined
              dense
              label="Khu vực"
              :item-text="(item) => getAreaText(item)"
              item-value="id"
              hide-details
            />
          </td>
          <td>
            <v-text-field
              @click="openDialog(form.id_khsx, 'add')"
              v-model="form.id_khsx"
              label="ID KHSX"
              outlined
              readonly
              dense
              style="width: auto"
              class="mx-1"
              hide-details
            >
            </v-text-field>
          </td>
          <td>
            <map-picker v-model="form.coordinate"></map-picker>
          </td>
          <td>
            <v-text-field
              v-model="form.note"
              label="Ghi chú"
              outlined
              dense
              style="width: auto"
              class="mx-1"
              hide-details
            />
          </td>
          <td class="text-center">
            <v-btn small hide-details color="success" @click="onPushAdd">
              Thêm
            </v-btn>
          </td>
        </tr>
      </tfoot>
    </table>
  </div>
</template>

<script>
import { ACTION_TABLE } from "@/constants/_action_table";
import { getAreas } from "@/api/khuvuc";
import { COORDINATE_DEFAULT } from "@/constants/_coordinate";
import API from "@/api/productadd";
import API_SX_KHACHHANG from "@/api/sx.khachhang.api";
import DiaLogIdKhachHangSx from "./dialog.vue";
export default {
  components: {
    DiaLogIdKhachHangSx,
  },
  props: {
    value: {
      type: Array,
      default: () => [],
    },
    id_khsx_lists: {
      type: Array,
      default: () => [],
    },
    customer_id: {
      type: [Number, String],
      default: null,
    },
    editing: {
      type: Boolean,
    },
  },
  data: () => ({
    local: "",
    id_khsx_current: null,
    all_id_khsx: [],
    area_lists: [],
    index_update: -1,
    form_edit: {
      name: "",
      address: "",
      area_offical_id: null,
      id_khsx: null,
      coordinate: {},
      note: "",
    },
    form: {
      name: "",
      address: "",
      area_offical_id: null,
      id_khsx: null,
      coordinate: {},
      note: "",
    },
  }),
  created() {
    this.getAreasData();
    this.getAllIdKhSx();
  },
  computed: {
    address: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit("input", value);
      },
    },
    _actions() {
      return ACTION_TABLE;
    },
  },
  methods: {
    // getUniqueIdKhsx(item) {
    //   Object.assign(item, { id_khsx: this.id_khsx_lists[0]["id"] });
    //   Object.assign(this.form_edit, item);
    //   return this.id_khsx_lists[0]["id"];
    // },
    openDialog(item, mode) {
      if (mode === "add") {
        this.$refs.diaLogIdKhSx.show(this.id_khsx_lists, item, mode);
      } else {
        this.$refs.diaLogIdKhSx.show(this.id_khsx_lists, item);
      }
    },
    bindChoosenId(item, mode) {
      if (mode === "add") {
        this.id_khsx_current = this.form.id_khsx;
        this.form.id_khsx = item.id;
      } else {
        this.id_khsx_current = this.form_edit.id_khsx;
        this.form_edit.id_khsx = item.id;
      }
    },
    async getAreasData() {
      let data = await getAreas({
        with: ["province", "district"],
      });
      this.area_lists = data.data;
    },
    async getAllIdKhSx() {
      let data = await API_SX_KHACHHANG.list();
      this.all_id_khsx = data.data;
    },
    async onPushAdd() {
      console.log(22222);

      let index_field = this.check_validete_form();
      if (index_field) {
        return this.errorNotify(this.notifyVaildate(index_field));
      }
      let data_create = Object.assign({}, this.form);
      if (this.editing) {
        data_create.customer_id = this.customer_id;
        const response = await API.create(data_create);
        if (response?.data) {
          this.address.push(response.data);
        } else {
          this.form.id_khsx = this.id_khsx_current;
        }
      } else {
        this.address.push(data_create);
      }
      this.form = this.$options.data.call(this).form;
    },
    check_validete_form() {
      let [index, arr] = [0, Object.values(this.form)];
      for (let i = 0; i < arr.length; i++) {
        if (i === 3 || i === 4) {
          continue;
        }
        if (i === 4 && (arr[i].lat === undefined || arr[i].lng === undefined)) {
          index = i + 1;
          break;
        }
        if (!arr[i] && i != 5) {
          index = i + 1;
          break;
        }
      }
      return index;
    },
    notifyVaildate(number) {
      console.log("number: ", number);
      switch (number) {
        case 1:
          return "Tên kho là bắt buộc";
        case 2:
          return "Địa chỉ là bắt buộc";
        case 3:
          return "Khu vực là bắt buộc";
        case 5:
          return "Tạo độ là bắt buộc";
        default:
          return "Something went wrong";
      }
    },
    errorNotify(message) {
      this.$toast.error(message, {
        position: "top-center",
        timeout: 1500,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        draggablePercent: 0.6,
        showCloseButtonOnHover: false,
        hideProgressBar: true,
        closeButton: "button",
        icon: true,
      });
    },
    getAreaName(id) {
      if (!id) return null;
      let item = this.area_lists.find((x) => x.id == id);
      return item ? item.name : null;
    },
    async onAction(action, index, item) {
      if (action == "UPDATE") {
        this.form_edit = Object.assign({}, item);
        this.index_update = index;
      } else if (action == "DELETE") {
        this.confirmDelete(index);
      }
    },
    confirmDelete(index) {
      this.$confirmBox.show({
        title: "Xóa địa chỉ lấy hàng",
        width: 500,
        body: "Bạn có chắc chắn muốn xóa địa chỉ lấy hàng không?",
        action: async () => this.handleDelete(index),
        // onDone: () => ({}),
      });
    },
    async handleDelete(index) {
      if (this.editing) {
        const response = await API.destroy(this.address[index].id);
        if (response.isDone) {
          this.address.splice(index, 1);
        }
      } else {
        this.address.splice(index, 1);
      }
    },
    async onUpdateItem(index) {
      const data = Object.assign(this.address[index], this.form_edit);
      console.log("this.address[index]: ", this.address[index], index);
      if (this.editing) {
        const response = await API.update(data.id, data);
        if (response.isDone) {
          this.address[index] = data;
        } else {
          this.form_edit.id_khsx = this.id_khsx_current;
          this.address[index].id_khsx = this.id_khsx_current;
        }
      } else {
        this.address[index] = data;
      }
      this.index_update = this.$options.data.call(this).index_update;
    },
    getFieldWithKey(key) {
      switch (key) {
        case "name":
          return "Tên kho";
        case "address":
          return "Địa chỉ";
        case "area_offical_id":
          return "Khu vực";
        case "id_khsx":
          return "ID KHSX";
        case "coordinate":
          return "Tạo độ";
        case "note":
          return "Ghi chú";
        default:
          return "Label";
      }
    },
    convertItem(item) {
      const tempt = Object.assign({}, item);
      return Object.entries(tempt);
    },
    getKeyAccess(data) {
      return data.map((x) => ({
        name: x.name,
        address: x.address,
        area_offical_id: x.area_offical_id,
        id_khsx: x.id_khsx,
        coordinate: x.coordinate,
        note: x.note,
      }));
    },
    rollback() {
      this.index_update = this.$options.data.call(this).index_update;
    },
    getAreaText({ name, district, province }) {
      if (!name) return null;
      if (!district) return name;
      if (!province) return `${name} / ${district?.name}`;
      return `${name} / ${district?.name} / ${province?.name}`;
    },
  },
};
</script>
<style scoped>
table {
  border: 1px solid #bbb;
  border-collapse: collapse;
  font: 16px "Lucida Grande", "Helvetica", "Arial", sans-serif;
  margin-bottom: 15px;
}
th,
td {
  border: 1px solid #bbb;
  padding: 5px 7px;
  text-align: left;
  /* width: 100px; */
  overflow: hidden;
}
th {
  font-size: 12px !important;
  font-weight: 200 !important;
}
</style>
